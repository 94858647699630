<template>
  <Card class="allSearchCard">
    <template slot="title">
      <div class="card-info">
        <div>
          <h3 class="name">{{cardInfo.accountName}}
            <!-- <Icon name="phone" /> -->
          </h3>
          <p>{{cardInfo.accountPhone}}</p>
        </div>
        <div>
          <span class="tag">{{cardInfo.tag}}</span>
        </div>

      </div>
    </template>
    <div class="description">
      <h4 class="accountMobile">
        {{cardInfo.qbsAccountPhone}}
      </h4>
    </div>
    <template slot="footer">
      <div class="footerContent"><span>{{cardInfo.storeName}}</span><span>{{cardInfo.appointmentDate}}&nbsp;&nbsp;&nbsp;{{cardInfo.appointmentTime}}</span>
        <Icon name="arrow" />
      </div>
    </template>
  </Card>
</template>
<script>
import { Icon } from 'vant'
import Card from '_c/card'
import 'vant/lib/icon/style'
import 'vant/lib/row/style'
import 'vant/lib/col/style'
import '@/style/vant-patch.less'
import './index.less'
export default {
  name: 'CardItem',
  props: {
    cardInfo: {
      type: Object,
      require: true
    },
    type: {
      type: String,
      require: true
    }
  },
  data () {
    return {
    }
  },
  components: {
    Card,
    Icon
  },
  methods: {
    init () {
      console.log(111)
    }
  },
  mounted () {
    this.init()
  }
}
</script>